import useTranslation from '@/hooks/useTranslation'
import { useAuth } from '@/providers/Auth'
import { DataTableTemplate } from '@/templates/index'
import { Table } from '@/organisms/index'
import {
  BreadcrumbItem,
  EmptyStateTable,
  FilterDropdown
} from '@/molecules/index'
import { Typography } from '@/atoms/index'
import {
  RecycleBinEnvelopeAccountItem,
  RecycleBinTemplateAccountItem
} from '@/hooks/api/ecm/useRecycleBinApi/useRecycleBinApi.types'
import useRecycleBinData from '@/hooks/useRecycleBinData/useRecycleBinData'
import { FiltersKeyRecycleBin } from '@/hooks/useRecycleBinData/useRecycleBinData.types'
import ModalSearchVault from '@/ui/molecules/ModalSearchVault'

const RecycleBinPage = () => {
  const { t, isReady } = useTranslation('recycleBin')
  const { isAdmin, userContext } = useAuth()

  const {
    optionsUser,
    optionsEntity,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    currentDataTable,
    columns,
    filters,
    preparedFilters,
    setPreparedFilters,
    handleApplyFilters,
    restoreModalData,
    setRestoreModalData,
    handleVaultRestore
  } = useRecycleBinData()

  const breadcrumbs: BreadcrumbItem[] = [
    { name: t?.breadcrumbs?.manage, href: null, current: false },
    { name: t?.breadcrumbs?.recycleBin, href: null, current: true }
  ]

  return (
    <DataTableTemplate
      header={
        <div className="flex flex-col gap-4 py-2 md:flex-row md:gap-2 md:justify-between md:items-center">
          <div className="flex flex-col w-full gap-1">
            <Typography
              variant="title-2xl-medium"
              className="text-gray-700 dark:text-gray-700"
              classNameSkeleton="w-40"
              showSkeleton={!isReady}
              type="h1"
            >
              {t?.title}
            </Typography>
            <Typography
              variant="text-sm-regular"
              className="text-gray-700 dark:text-gray-700"
              classNameSkeleton="w-60"
              showSkeleton={!isReady}
              type="p"
            >
              {t?.description}
            </Typography>
          </div>
          <div className="flex flex-row items-end justify-end w-full gap-2 md:items-center md:w-fit">
            <FilterDropdown
              filtersSchema={[
                {
                  id: FiltersKeyRecycleBin.USER,
                  type: 'select',
                  label: t?.selectLabelUser,
                  initialValue: userContext?.userAccountInformation?.id,
                  options: optionsUser,
                  showSkeleton: !isReady,
                  hidden: !isAdmin,
                  isActive: Boolean(
                    isAdmin &&
                      !!filters?.user &&
                      filters?.user !== userContext?.userAccountInformation?.id
                  )
                },
                {
                  id: FiltersKeyRecycleBin.ENTITY,
                  type: 'select',
                  label: t?.selectLabelEntity,
                  showSkeleton: !isReady || !filters?.entity,
                  initialValue: 'envelopes',
                  options: optionsEntity,
                  isActive: Boolean(
                    filters?.entity && filters?.entity !== 'envelopes'
                  )
                }
              ]}
              values={{
                user:
                  (preparedFilters?.user as string) ||
                  userContext?.userAccountInformation?.id,
                entity:
                  (preparedFilters?.entity as 'envelopes' | 'templates') ||
                  'envelopes'
              }}
              onFilter={async (filters) => {
                handleApplyFilters({
                  user:
                    filters?.user === userContext?.userAccountInformation?.id
                      ? null
                      : filters?.user,
                  entity: filters?.entity as 'envelopes' | 'templates'
                })
              }}
              setPreparedValues={setPreparedFilters}
              showSkeleton={!isReady}
            />
          </div>
        </div>
      }
      showSkeleton={!isReady}
      breadcrumbs={breadcrumbs}
    >
      {currentDataTable?.isLoading ||
      (currentDataTable?.isSuccess &&
        currentDataTable?.data &&
        currentDataTable?.data?.items?.length > 0) ? (
        <div className="flex items-center justify-center">
          <Table<RecycleBinEnvelopeAccountItem | RecycleBinTemplateAccountItem>
            columns={columns}
            data={currentDataTable?.data?.items || []}
            fetchData={setPageNumber}
            canPreviousPage={pageNumber > 0}
            canNextPage={
              (currentDataTable?.data?.pagination &&
                currentDataTable?.data?.pagination?.totalItems /
                  currentDataTable?.data?.pagination?.pageSize >
                  pageNumber + 1) ||
              false
            }
            pagination={currentDataTable?.data?.pagination}
            isLoading={currentDataTable?.isLoading}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
          />
        </div>
      ) : (
        <EmptyStateTable
          type={
            filters?.entity === 'envelopes'
              ? 'recycleBinEnvelope'
              : 'recycleBinTemplate'
          }
        />
      )}
      {restoreModalData.id && restoreModalData.open && (
        <ModalSearchVault
          isOpen={restoreModalData.open}
          onClose={() => {
            setRestoreModalData({ id: null, open: false })
          }}
          onSearchVaultCallback={handleVaultRestore}
          actionType="restoreRecycleBin"
        />
      )}
    </DataTableTemplate>
  )
}

export default RecycleBinPage
