import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useAuth } from '@/providers/Auth'
import { Input, Typography } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'
import { AuthTemplate } from '@/ui/templates'

const GoogleRegisterPage = () => {
  const router = useRouter()
  const { googleRegisterAction } = useAuth()
  const [requested, setRequested] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const getStringQueryParam = useCallback(
    (param: any) => (Array.isArray(param) ? param[0] : param),
    []
  )

  const getRecaptchaToken = useCallback(async () => {
    const token = executeRecaptcha
      ? await executeRecaptcha('register_with_google')
      : null
    if (token) {
      setRecaptchaToken(token)
    }
  }, [executeRecaptcha])

  useEffect(() => {
    getRecaptchaToken()
  }, [getRecaptchaToken])

  useEffect(() => {
    if (!requested && router.isReady && !!recaptchaToken) {
      googleRegisterAction(
        getStringQueryParam(router.query.code),
        recaptchaToken
      )
      setRequested(true)
    }
  }, [
    router,
    requested,
    getStringQueryParam,
    setRequested,
    googleRegisterAction,
    recaptchaToken
  ])

  return (
    <AuthTemplate>
      <div className="flex flex-col items-start justify-start gap-6">
        <div className="flex flex-col items-start justify-start gap-1">
          <Typography
            type="h1"
            variant="title-2xl-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={true}
          ></Typography>
          <Typography
            variant="text-sm-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={true}
          ></Typography>
        </div>
        <Button
          variant="neutral"
          className="flex items-center justify-center w-full gap-2 py-2 pl-3 pr-3 border border-gray-300 rounded-md shadow bg-accent-100"
          fullWidth
          showSkeleton={true}
        ></Button>
        <div className="inline-flex items-center self-stretch justify-center gap-2">
          <div className="grow shrink basis-0 h-[1px] bg-gray-300" />
          <Typography
            type="p"
            variant="text-sm-regular"
            className="text-gray-500"
            showSkeleton={true}
          ></Typography>
          <div className="grow shrink basis-0 h-[1px] bg-gray-300" />
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <>
          <div className="flex flex-col gap-6">
            <Input
              name="email"
              id="email"
              autoComplete="email"
              type="email"
              showSkeleton={true}
            />
            <Input
              type="password"
              name="password"
              id="password"
              autoComplete="current-password"
              showSkeleton={true}
            />
            <div className="flex items-center justify-between">
              <div className="w-32 h-5 skeleton"></div>
              <div className="text-sm leading-6">
                <Typography
                  variant="text-sm-semibold"
                  className="cursor-pointer text-secondary-700"
                  showSkeleton={true}
                ></Typography>
              </div>
            </div>
          </div>
          <Button type="submit" fullWidth showSkeleton={true}></Button>
        </>
        <div className="inline-flex items-center justify-center gap-1 sm:gap-2">
          <Typography
            variant="text-sm-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={true}
          ></Typography>
          <Typography
            variant="text-sm-semibold"
            className="cursor-pointer text-secondary-700"
            showSkeleton={true}
          ></Typography>
        </div>
      </div>
      <NextSeo
        noindex
        nofollow
        title="Signater - Registro com Google"
        description="Crie uma conta Signater com sua conta Google"
        canonical="https://app.signater.com.br/login"
        openGraph={{
          url: 'https://app.signater.com.br/register/google',
          title: 'Signater - Registro com Google',
          description: 'Crie uma conta Signater com sua conta Google',
          type: 'website',
          images: [{ url: '/public/meta/signater.webp' }],
          siteName: 'Signater',
          locale: 'pt_BR'
        }}
        twitter={{
          site: 'https://signater.com.br',
          cardType: 'summary_large_image'
        }}
      />
    </AuthTemplate>
  )
}

export default GoogleRegisterPage
