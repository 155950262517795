import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import { AuthTemplate } from '@/templates/index'
import { InviteForm, RegisterForm } from '@/organisms/index'

const RegisterPage = () => {
  const { query } = useRouter()

  const isRegister = !query?.inviteId

  return (
    <AuthTemplate>
      {isRegister ? (
        <RegisterForm />
      ) : (
        <InviteForm inviteId={query?.inviteId as string} />
      )}
      <NextSeo
        title="Signater - Registro"
        description="Crie uma conta Signater"
        canonical="https://app.signater.com.br/login"
        openGraph={{
          url: 'https://app.signater.com.br/register',
          title: 'Signater - Registro',
          description: 'Crie uma conta Signater',
          type: 'website',
          images: [{ url: '/public/meta/signater.webp' }],
          siteName: 'Signater',
          locale: 'pt_BR'
        }}
        twitter={{
          site: 'https://signater.com.br',
          cardType: 'summary_large_image'
        }}
      />
    </AuthTemplate>
  )
}

export default RegisterPage
