import { useEffect, useMemo, useState } from 'react'
import { PencilIcon } from '@heroicons/react/24/solid'
import { Share2Icon } from '@radix-ui/react-icons'
import { IoCloudOfflineOutline, IoWaterOutline } from 'react-icons/io5'
import { LuTrees } from 'react-icons/lu'
import useTranslation from '@/hooks/useTranslation'
import withRole from '@/hoc/withRole'
import useAccountSustainabilityCertificatesApi from '@/hooks/api/report/useAccountSustainabilityCertificatesApi/useAccountSustainabilityCertificatesApi'
import { cn } from '@/lib/utils'
import { useLocale } from '@/providers'
import { Toggle, Typography } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'
import {
  ButtonWithTooltip,
  CardStatsBadge,
  ModalAccountSustainabilityBadge
} from '@/ui/molecules'
import { SettingsTemplate } from '@/ui/templates'

type ValueToggle = boolean | null

type Card = {
  name: 'trees' | 'water' | 'co2'
  label: (value?: string | number) => string
  description: string
}

type DataCard = {
  icon: React.ReactNode
  value: number
  label: string
  description: string
}

type ModalActionType = {
  isOpen: boolean
  action: 'share' | 'rename' | null
}

const AccountSustainabilityBadgePage: React.FC = () => {
  const { t, isReady } = useTranslation(
    ['accountSustainabilityBadge', 'validations'],
    true
  )
  const [modalAction, setModalAction] = useState<ModalActionType>({
    isOpen: false,
    action: null
  })
  const [valueToggle, setValueToggle] = useState<ValueToggle>(null)
  const {
    useGetAccountSustainabilityCertificates,
    useUpdateAccountSustainabilityCertificates
  } = useAccountSustainabilityCertificatesApi()
  const {
    data,
    isFetched: isFetchedGet,
    isLoading: isLoadingGet
  } = useGetAccountSustainabilityCertificates()
  const { mutateAsync, isLoading: isLoadingUpdate } =
    useUpdateAccountSustainabilityCertificates()

  useEffect(() => {
    if (isFetchedGet && data && valueToggle === null) {
      setValueToggle(data?.isActive)
    }
  }, [data, isFetchedGet, valueToggle])

  const showSkeleton = useMemo(
    () => !isReady || isLoadingGet,
    [isLoadingGet, isReady]
  )

  const waterInLiters = useMemo(() => {
    if (!data?.waterInMilliliters) return 0
    return data?.waterInMilliliters / 1000
  }, [data?.waterInMilliliters])

  const co2Details = useMemo(() => {
    if (!data?.co2InGrams) return { amount: 0, unit: '' }

    let amount = 0
    let unit = ''

    if (data?.co2InGrams >= 1000 && data?.co2InGrams < 1000000) {
      amount = Math.round(data?.co2InGrams / 1000)
      unit = amount >= 2 ? t?.co2?.kilos : t?.co2?.kilo
    } else if (data?.co2InGrams >= 1000000) {
      amount = Math.round(data?.co2InGrams / 1000000)
      unit = amount >= 2 ? t?.co2?.tons : t?.co2?.ton
    } else {
      amount = Math.round(data?.co2InGrams)
      unit = t?.co2?.grams
    }

    return { amount, unit }
  }, [
    data?.co2InGrams,
    t?.co2?.grams,
    t?.co2?.kilo,
    t?.co2?.kilos,
    t?.co2?.ton,
    t?.co2?.tons
  ])

  const dataCards: DataCard[] = useMemo(() => {
    const mappingIcons = {
      trees: <LuTrees className="w-8 h-8 mr-2 text-primary-700" />,
      water: <IoWaterOutline className="w-8 h-8 mr-2 text-primary-700" />,
      co2: <IoCloudOfflineOutline className="w-8 h-8 mr-2 text-primary-700" />
    }
    const mappingValues: Record<string, number | undefined> = {
      trees: data?.trees,
      water: waterInLiters,
      co2: co2Details.amount
    }
    const mappingUnits: Record<string, number | string | undefined> = {
      trees: data?.trees,
      water: waterInLiters,
      co2: co2Details.unit
    }
    return t?.cards?.map((card: Card) => {
      const icon = mappingIcons[card.name]
      const value = mappingValues[card.name]
      const unit = mappingUnits[card.name]
      return {
        icon,
        value,
        label: card.label(unit),
        description: card.description
      }
    })
  }, [co2Details, data?.trees, t?.cards, waterInLiters])

  return (
    <>
      <SettingsTemplate
        showSkeleton={!isReady}
        className="w-full sm:w-full xl:min-w-4xl"
        renderHeader={
          <div className="flex flex-col items-center justify-between w-full gap-2 md:flex-row md:gap-1">
            <div className="flex flex-col w-full gap-1">
              <div className={cn('flex items-center', showSkeleton && 'gap-1')}>
                <Typography
                  variant="title-2xl-medium"
                  className="text-gray-700 dark:text-gray-700"
                  type="h1"
                  classNameSkeleton="w-40"
                  showSkeleton={showSkeleton}
                >
                  {data?.name}
                </Typography>
                <ButtonWithTooltip
                  variant="neutral"
                  className="px-2 ml-2 bg-transparent border-none cursor-pointer hover:bg-transparent sm:px-2 md:px-2 lg:p-2"
                  onClick={() =>
                    setModalAction({ isOpen: true, action: 'rename' })
                  }
                  tooltipText={t?.tooltipRename}
                  showSkeleton={showSkeleton}
                >
                  <PencilIcon className="w-4 h-4 text-gray-700 hover:text-gray-500" />
                </ButtonWithTooltip>
              </div>
              <Typography
                variant="text-sm-regular"
                className="text-gray-700 dark:text-gray-700"
                type="p"
                showSkeleton={showSkeleton}
              >
                {t?.description}
              </Typography>
            </div>
            <div className="flex items-center justify-between w-full h-full gap-2 md:justify-end md:w-fit">
              <Toggle
                label={t?.form?.isActive?.label}
                onChange={async (checked) => {
                  setValueToggle(checked)
                  try {
                    await mutateAsync({
                      isActive: checked,
                      name: data?.name || ''
                    })
                  } catch (error) {
                    console.error(error)
                    setValueToggle(!checked)
                  }
                }}
                name="isActive"
                disabled={isLoadingUpdate}
                checked={valueToggle as boolean}
                showSkeleton={showSkeleton}
              />
              <Button
                onClick={() =>
                  setModalAction({
                    isOpen: true,
                    action: 'share'
                  })
                }
                showSkeleton={showSkeleton}
                variant="outlineSecondary"
              >
                <Share2Icon className="w-5 h-5" />
                {t?.button?.share}
              </Button>
            </div>
          </div>
        }
      >
        {/* <div className="flex flex-col items-end w-full gap-1">
          {data?.lastProcessedAtUtc && (
            <Typography
              type="p"
              variant="text-sm-regular"
              className="text-gray-600"
              showSkeleton={showSkeleton}
            >
              {t?.lastProcessedAtUtc}{' '}
              {formatDate(data?.lastProcessedAtUtc, 'datetime', lang)}
            </Typography>
          )} */}
        <div className="grid w-full grid-cols-1 gap-5 lg:grid-cols-3">
          {showSkeleton
            ? Array.from({ length: 3 }).map((_, index) => (
                <div className="w-full h-60 skeleton rounded-2xl" key={index} />
              ))
            : dataCards?.map((card, index) => (
                <CardStatsBadge key={index} {...card} />
              ))}
        </div>
        {/* </div> */}
      </SettingsTemplate>
      <ModalAccountSustainabilityBadge
        onClose={() => setModalAction({ isOpen: false, action: null })}
        {...modalAction}
        data={data}
      />
    </>
  )
}

export default withRole(AccountSustainabilityBadgePage, 'Administrator')
