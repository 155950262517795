import { UseQueryResult } from '@tanstack/react-query'
import { CustomColumn } from '@/types/react-table-config'
import {
  GetListWebhooksActiveResponse,
  GetListWebhooksInactiveResponse,
  GetWebhooksCLIResponse,
  WebhookActiveType,
  WebhookInactiveType
} from '../api/core/useWebhooksApi/useWebhooksApi.types'

export type ValueToggle = boolean | null

export type UseWebhooksDataResponse = {
  columns: CustomColumn<WebhookActiveType | WebhookInactiveType>[]
  dataList:
    | {
        data?: GetListWebhooksActiveResponse | null
        isLoading: boolean
        isSuccess: boolean
      }
    | {
        data?: GetListWebhooksInactiveResponse | null
        isLoading: boolean
        isSuccess: boolean
      }
  handleAction: (
    action: ModalActionType['action'],
    values: WebhooksFormType
  ) => Promise<void>
  handleConfirm: (
    action: 'activate' | 'deactivate',
    id: string
  ) => Promise<void>
  showSkeleton: boolean
  pageNumber: number
  setPageNumber: (pageNumber: number) => void
  pageSize: number
  setPageSize: (pageSize: number) => void
  modalAction: ModalActionType
  setModalAction: React.Dispatch<React.SetStateAction<ModalActionType>>
  isLoadingSubmit: boolean
  modalError: boolean
  setModalError: React.Dispatch<React.SetStateAction<boolean>>
  filters: FiltersWebhooksType | null
  handleApplyFilters: (filters: FiltersWebhooksType) => void
  preparedFilters: FiltersWebhooksType | null
  setPreparedFilters: (filters: FiltersWebhooksType) => void
  valueToggle: ValueToggle
  handleToggleCLI: (isActive: boolean) => void
  isLoadingUpdateCLI: boolean
  dataCLI: UseQueryResult<GetWebhooksCLIResponse | null, unknown>
}

export type ModalActionType = {
  isOpen: boolean
  action:
    | 'create'
    | 'update'
    | 'view'
    | 'deactivate'
    | 'activate'
    | 'editCLI'
    | null
  id: string | null
}

export type FiltersWebhooksType = {
  query: string | null
  typeView: 'active' | 'inactive'
}

export enum FiltersKeyWebhooks {
  QUERY = 'query',
  TYPE_VIEW = 'typeView'
}

export type WebhooksFormType = {
  name: string
  description: string | null
  url: string | null
  httpMethod: string
  authMethod: string
  eventTypes: string[]
}

export type WebhooksCLIFormType = {
  url: string | null
  isActive: boolean
}
