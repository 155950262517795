import { useMemo } from 'react'
import { useRouter } from 'next/router'
import useTranslation from '@/hooks/useTranslation'
import { useWebhooksData } from '@/hooks/useWebhooksData'
import withRole from '@/hoc/withRole'
import {
  WebhookActiveType,
  WebhookInactiveType
} from '@/hooks/api/core/useWebhooksApi/useWebhooksApi.types'
import {
  FiltersKeyWebhooks,
  WebhooksCLIFormType,
  WebhooksFormType
} from '@/hooks/useWebhooksData/useWebhooksData.types'
import { DropdownButton, Toggle, Typography } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'
import {
  EmptyStateTable,
  FilterDropdown,
  ModalActionWebhooks,
  ModalConfirm,
  ModalHelpInfo
} from '@/ui/molecules'
import { Table } from '@/ui/organisms'
import { SettingsTemplate } from '@/ui/templates'

const AccountWebhooksPage: React.FC = () => {
  const { t } = useTranslation('accountWebhooks')
  const { push } = useRouter()

  const {
    columns,
    dataList,
    handleAction,
    handleConfirm,
    showSkeleton,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    modalAction,
    setModalAction,
    isLoadingSubmit,
    modalError,
    setModalError,
    filters,
    handleApplyFilters,
    preparedFilters,
    setPreparedFilters,
    valueToggle,
    handleToggleCLI,
    isLoadingUpdateCLI,
    dataCLI
  } = useWebhooksData()

  const tModalConfirm = useMemo(() => {
    return modalAction.action === 'activate'
      ? t?.modalConfirm?.activate
      : modalAction.action === 'deactivate'
        ? t?.modalConfirm?.deactivate
        : null
  }, [
    modalAction.action,
    t?.modalConfirm?.activate,
    t?.modalConfirm?.deactivate
  ])

  return (
    <>
      <SettingsTemplate
        showSkeleton={showSkeleton}
        className="w-full sm:w-full xl:min-w-4xl"
        renderHeader={
          <div className="flex items-center justify-between w-full gap-2">
            <div className="flex flex-col w-full gap-1">
              <Typography
                variant="title-2xl-medium"
                className="text-gray-700 dark:text-gray-700"
                type="h1"
                classNameSkeleton="w-40"
                showSkeleton={showSkeleton}
              >
                {t?.title}
              </Typography>
              <Typography
                variant="text-sm-regular"
                className="text-gray-700 dark:text-gray-700"
                type="p"
                showSkeleton={showSkeleton}
              >
                {t?.description}
              </Typography>
            </div>
            <div className="flex items-center justify-end w-full h-full gap-2">
              {/* <Toggle
                label={t?.toogleActiveCLI}
                onChange={handleToggleCLI}
                name="isActive"
                disabled={isLoadingUpdateCLI}
                checked={valueToggle as boolean}
                showSkeleton={showSkeleton}
              /> */}
              <FilterDropdown
                filtersSchema={[
                  {
                    id: FiltersKeyWebhooks.QUERY,
                    type: 'inputSearch',
                    label: t?.filterQuery?.label,
                    placeholder: t?.filterQuery?.placeholder,
                    initialValue: '',
                    isActive: !!filters?.query,
                    showSkeleton: showSkeleton
                  },
                  {
                    id: FiltersKeyWebhooks.TYPE_VIEW,
                    type: 'select',
                    label: t?.filterTypeView?.label,
                    placeholder: t?.selectPlaceholder,
                    initialValue: 'active',
                    options: t?.optionsTypeView,
                    showSkeleton: showSkeleton || !filters?.typeView,
                    isActive:
                      filters?.typeView !== 'active' && !!filters?.typeView
                  }
                ]}
                values={{
                  query: preparedFilters?.query || '',
                  typeView: preparedFilters?.typeView || 'active'
                }}
                setPreparedValues={setPreparedFilters}
                onFilter={(filters) => {
                  setPageNumber(0)

                  const query = filters?.query ? filters?.query : null
                  const typeView = filters?.typeView
                    ? filters?.typeView
                    : 'active'

                  handleApplyFilters({
                    query: query as string | null,
                    typeView: typeView as 'active' | 'inactive'
                  })
                }}
                showSkeleton={showSkeleton}
              />
              <DropdownButton
                label={t?.buttonCreate}
                dropdownItems={[
                  {
                    key: 'editCLI',
                    label: t?.buttonEditCLI,
                    onClick: () =>
                      setModalAction({
                        action: 'editCLI',
                        isOpen: true,
                        id: null
                      })
                  }
                ]}
                onClick={(key, e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  if (key === 'main' || key === 'editCLI') {
                    const action = key === 'main' ? 'create' : 'editCLI'
                    setModalAction({
                      action: action,
                      isOpen: true,
                      id: null
                    })
                  }
                }}
                showSkeleton={showSkeleton}
              />
            </div>
          </div>
        }
      >
        {dataList?.isLoading ||
        (dataList?.isSuccess &&
          dataList?.data &&
          dataList?.data?.items?.length > 0) ? (
          <div className="flex items-center justify-center">
            <Table<WebhookActiveType | WebhookInactiveType>
              columns={columns}
              data={dataList?.data?.items || []}
              fetchData={setPageNumber}
              canPreviousPage={pageNumber > 0}
              canNextPage={
                (dataList?.data?.pagination &&
                  dataList?.data?.pagination?.totalItems /
                    dataList?.data?.pagination?.pageSize >
                    pageNumber + 1) ||
                false
              }
              pagination={dataList?.data?.pagination}
              isLoading={dataList?.isLoading}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
            />
          </div>
        ) : (
          <EmptyStateTable type="accountWebhooks" />
        )}
      </SettingsTemplate>
      {(modalAction.action === 'create' ||
        modalAction.action === 'update' ||
        modalAction.action === 'editCLI' ||
        modalAction.action === 'view') && (
        <ModalActionWebhooks
          isOpen={modalAction.isOpen}
          action={modalAction.action}
          onClose={() =>
            setModalAction({ isOpen: false, action: null, id: null })
          }
          onSubmit={(action, values) => handleAction(action, values)}
          setModalAction={setModalAction}
          isLoadingSubmit={isLoadingSubmit}
          dataCLI={dataCLI}
          id={modalAction.id}
          setModalError={setModalError}
        />
      )}
      <ModalConfirm
        isOpen={
          (modalAction.action === 'activate' ||
            modalAction.action === 'deactivate') &&
          modalAction.isOpen
        }
        title={tModalConfirm?.title}
        description={tModalConfirm?.description}
        cancelButtonText={tModalConfirm?.cancelButtonText}
        confirmButtonText={tModalConfirm?.confirmButtonText}
        onClose={() =>
          setModalAction({ isOpen: false, action: null, id: null })
        }
        onConfirm={async () =>
          await handleConfirm(
            modalAction.action as 'activate' | 'deactivate',
            modalAction.id as string
          )
        }
        loadingSubmit={isLoadingSubmit}
      />
      <ModalHelpInfo
        isOpen={modalError}
        onClose={() => setModalError(false)}
        title={t?.modalError?.title}
        description={t?.modalError?.description}
        status="error"
        continueButtonText={t?.modalError?.continueButtonText}
        onContinue={() => push('/billing/plans')}
      />
    </>
  )
}

export default withRole(AccountWebhooksPage, 'Administrator')
