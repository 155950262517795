import { useCallback, useEffect, useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import { useNotificationsApi } from '@/hooks/api/administration/account/settings/useNotificationsApi'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'
import {
  Form,
  NotificationToggle,
  NotificationToggleSkeleton,
  RenderFormField
} from '@/ui/molecules'
import { SettingsTemplate } from '@/ui/templates'

type FormValues = {
  email: string
  envelopePublished: boolean
  envelopeScheduled: boolean
  scheduledEnvelopePublished: boolean
  envelopeViewed: boolean
  envelopeApproved: boolean
  envelopeRejected: boolean
  envelopeCompleted: boolean
  envelopeExpired: boolean
  envelopeCancelled: boolean
  envelopeCancelledDueToSignerAuthError: boolean
}

const AccountNotificationsPage = () => {
  const [insertedInitialValues, setInsertedInitialValues] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t, isReady } = useTranslation(
    ['accountNotifications', 'validations'],
    true
  )

  const { useGetNotifications, useUpdateNotifications } = useNotificationsApi()
  const { data: dataNotifications } = useGetNotifications()
  const { mutateAsync: mutateAsyncUpdateNotifications } =
    useUpdateNotifications()

  const formSchema = z.object({
    email: z
      .string()
      .email({ message: t?.errorInvalidEmail })
      .min(2, {
        message: t?.errorMinLength?.(2)
      })
      .max(255, {
        message: t?.errorMaxLength?.(255)
      }),
    envelopePublished: z.boolean(),
    envelopeScheduled: z.boolean(),
    scheduledEnvelopePublished: z.boolean(),
    envelopeViewed: z.boolean(),
    envelopeApproved: z.boolean(),
    envelopeRejected: z.boolean(),
    envelopeCompleted: z.boolean(),
    envelopeExpired: z.boolean(),
    envelopeCancelled: z.boolean(),
    envelopeCancelledDueToSignerAuthError: z.boolean()
  })

  const onSubmit = useCallback(
    async (values) => {
      setLoading(true)
      try {
        await mutateAsyncUpdateNotifications(values)
        showToast.success(t?.toasts?.successUpdate)
      } catch {
        showToast.error(t?.toasts?.errorUpdate)
      } finally {
        setLoading(false)
      }
    },
    [
      mutateAsyncUpdateNotifications,
      t?.toasts?.errorUpdate,
      t?.toasts?.successUpdate
    ]
  )

  const showSkeleton = useMemo(() => {
    return !isReady || !insertedInitialValues
  }, [insertedInitialValues, isReady])

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      envelopePublished: false,
      envelopeScheduled: false,
      scheduledEnvelopePublished: false,
      envelopeViewed: false,
      envelopeApproved: false,
      envelopeRejected: false,
      envelopeCompleted: false,
      envelopeExpired: false,
      envelopeCancelled: false,
      envelopeCancelledDueToSignerAuthError: false
    }
  })

  useEffect(() => {
    if (!insertedInitialValues && dataNotifications) {
      form.reset({
        email: dataNotifications.email,
        envelopePublished: dataNotifications.envelopePublished,
        envelopeScheduled: dataNotifications.envelopeScheduled,
        scheduledEnvelopePublished:
          dataNotifications.scheduledEnvelopePublished,
        envelopeViewed: dataNotifications.envelopeViewed,
        envelopeApproved: dataNotifications.envelopeApproved,
        envelopeRejected: dataNotifications.envelopeRejected,
        envelopeCompleted: dataNotifications.envelopeCompleted,
        envelopeExpired: dataNotifications.envelopeExpired,
        envelopeCancelled: dataNotifications.envelopeCancelled,
        envelopeCancelledDueToSignerAuthError:
          dataNotifications.envelopeCancelledDueToSignerAuthError
      })
      setInsertedInitialValues(true)
    }
  }, [dataNotifications, form, insertedInitialValues])

  return (
    <SettingsTemplate
      title={t?.title}
      description={t?.description}
      showSkeleton={!isReady}
      className="w-full max-w-4xl"
    >
      <Form {...form} onSubmit={onSubmit} className="gap-4">
        {!showSkeleton ? (
          <>
            <RenderFormField
              control={form.control}
              name="email"
              type="email"
              disabled={false}
              showSkeleton={showSkeleton}
              {...form.formState.errors.email}
              {...t?.form?.email}
            />
            <RenderFormField
              control={form.control}
              name="envelopePublished"
              type="notificationToggle"
              disabled={false}
              showSkeleton={showSkeleton}
              {...form.formState.errors.envelopePublished}
              {...t?.form?.envelopePublished}
              render={({ field }) => (
                <NotificationToggle
                  name={field.name}
                  title={t?.form?.envelopePublished?.title}
                  description={t?.form?.envelopePublished?.description}
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <RenderFormField
              control={form.control}
              name="envelopeScheduled"
              type="notificationToggle"
              disabled={false}
              showSkeleton={showSkeleton}
              {...form.formState.errors.envelopeScheduled}
              {...t?.form?.envelopeScheduled}
              render={({ field }) => (
                <NotificationToggle
                  name={field.name}
                  title={t?.form?.envelopeScheduled?.title}
                  description={t?.form?.envelopeScheduled?.description}
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <RenderFormField
              control={form.control}
              name="scheduledEnvelopePublished"
              type="notificationToggle"
              disabled={false}
              showSkeleton={showSkeleton}
              {...form.formState.errors.scheduledEnvelopePublished}
              {...t?.form?.scheduledEnvelopePublished}
              render={({ field }) => (
                <NotificationToggle
                  name={field.name}
                  title={t?.form?.scheduledEnvelopePublished?.title}
                  description={t?.form?.scheduledEnvelopePublished?.description}
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <RenderFormField
              control={form.control}
              name="envelopeViewed"
              type="notificationToggle"
              disabled={false}
              showSkeleton={showSkeleton}
              {...form.formState.errors.envelopeViewed}
              {...t?.form?.envelopeViewed}
              render={({ field }) => (
                <NotificationToggle
                  name={field.name}
                  title={t?.form?.envelopeViewed?.title}
                  description={t?.form?.envelopeViewed?.description}
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <RenderFormField
              control={form.control}
              name="envelopeApproved"
              type="notificationToggle"
              disabled={false}
              showSkeleton={showSkeleton}
              {...form.formState.errors.envelopeApproved}
              {...t?.form?.envelopeApproved}
              render={({ field }) => (
                <NotificationToggle
                  name={field.name}
                  title={t?.form?.envelopeApproved?.title}
                  description={t?.form?.envelopeApproved?.description}
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <RenderFormField
              control={form.control}
              name="envelopeRejected"
              type="notificationToggle"
              disabled={false}
              showSkeleton={showSkeleton}
              {...form.formState.errors.envelopeRejected}
              {...t?.form?.envelopeRejected}
              render={({ field }) => (
                <NotificationToggle
                  name={field.name}
                  title={t?.form?.envelopeRejected?.title}
                  description={t?.form?.envelopeRejected?.description}
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <RenderFormField
              control={form.control}
              name="envelopeCompleted"
              type="notificationToggle"
              disabled={false}
              showSkeleton={showSkeleton}
              {...form.formState.errors.envelopeCompleted}
              {...t?.form?.envelopeCompleted}
              render={({ field }) => (
                <NotificationToggle
                  name={field.name}
                  title={t?.form?.envelopeCompleted?.title}
                  description={t?.form?.envelopeCompleted?.description}
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <RenderFormField
              control={form.control}
              name="envelopeExpired"
              type="notificationToggle"
              disabled={false}
              showSkeleton={showSkeleton}
              {...form.formState.errors.envelopeExpired}
              {...t?.form?.envelopeExpired}
              render={({ field }) => (
                <NotificationToggle
                  name={field.name}
                  title={t?.form?.envelopeExpired?.title}
                  description={t?.form?.envelopeExpired?.description}
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <RenderFormField
              control={form.control}
              name="envelopeCancelled"
              type="notificationToggle"
              disabled={false}
              showSkeleton={showSkeleton}
              {...form.formState.errors.envelopeCancelled}
              {...t?.form?.envelopeCancelled}
              render={({ field }) => (
                <NotificationToggle
                  name={field.name}
                  title={t?.form?.envelopeCancelled?.title}
                  description={t?.form?.envelopeCancelled?.description}
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <RenderFormField
              control={form.control}
              name="envelopeCancelledDueToSignerAuthError"
              type="notificationToggle"
              disabled={false}
              showSkeleton={showSkeleton}
              {...form.formState.errors.envelopeCancelledDueToSignerAuthError}
              {...t?.form?.envelopeCancelledDueToSignerAuthError}
              render={({ field }) => (
                <NotificationToggle
                  name={field.name}
                  title={t?.form?.envelopeCancelledDueToSignerAuthError?.title}
                  description={
                    t?.form?.envelopeCancelledDueToSignerAuthError?.description
                  }
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </>
        ) : (
          Array.from({ length: 8 }).map((_, id) => (
            <NotificationToggleSkeleton key={`card-notification-${id}`} />
          ))
        )}
        <div className="flex justify-end">
          <Button
            className="w-fit"
            type="submit"
            showSkeleton={showSkeleton}
            loading={loading}
          >
            {t?.buttonSubmitSave}
          </Button>
        </div>
      </Form>
    </SettingsTemplate>
  )
}

export default AccountNotificationsPage
