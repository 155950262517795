import { useCallback, useEffect, useMemo, useState } from 'react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-toastify'
import { formatData } from '@/utils/date'
import { useLocale } from '@/providers/Locale'
import { CustomColumn } from '@/types/react-table-config'
import { Avatar, Dropdown, MenuItem, Typography } from '@/atoms/index'
import {
  FiltersKeyWebhooks,
  FiltersWebhooksType,
  ModalActionType,
  UseWebhooksDataResponse,
  ValueToggle,
  WebhooksFormType
} from './useWebhooksData.types'
import { useWebhooksApi } from '../api/core/useWebhooksApi'
import {
  WebhookActiveType,
  WebhookInactiveType
} from '../api/core/useWebhooksApi/useWebhooksApi.types'
import { useTable } from '../useTable'
import useTableFilters from '../useTableFilters'
import useTranslation from '../useTranslation'
import { useViewport } from '../useViewport'
import { IBreakpoint } from '../useViewport/useViewport'

const useWebhooksData = (): UseWebhooksDataResponse => {
  const { t, isReady } = useTranslation('accountWebhooks')
  const [modalAction, setModalAction] = useState<ModalActionType>({
    isOpen: false,
    action: null,
    id: null
  })
  const [modalError, setModalError] = useState(false)
  const [isInsertedInitialFilters, setIsInsertedInitialFilters] =
    useState<boolean>(false)
  const [valueToggle, setValueToggle] = useState<ValueToggle>(null)

  const { pageNumber, pageSize, setPageNumber, setPageSize } = useTable()
  const { breakpoint } = useViewport()
  const { lang } = useLocale()
  const {
    filters,
    preparedFilters,
    setPreparedFilters,
    setFilter,
    handleApplyFilters,
    isReady: isReadyFilters
  } = useTableFilters<FiltersWebhooksType>({
    query: null,
    typeView: 'active'
  })
  const {
    useGetListWebhooksActive,
    useGetListWebhooksInactive,
    useUpdateWebhook,
    useActivateWebhook,
    useDeactivateWebhook,
    useCreateWebhook,
    useGetWebhooksCLI,
    useUpdateWebhooksCLI
  } = useWebhooksApi()
  const {
    data: dataListActive,
    isLoading: isLoadingActive,
    isSuccess: isSuccessActive
  } = useGetListWebhooksActive(
    filters?.typeView === 'active',
    filters?.query || null,
    pageNumber,
    pageSize
  )
  const {
    data: dataListInactive,
    isLoading: isLoadingInactive,
    isSuccess: isSuccessInactive
  } = useGetListWebhooksInactive(
    filters?.typeView === 'inactive',
    filters?.query || null,
    pageNumber,
    pageSize
  )
  const { mutateAsync: mutateAsyncCreate, isLoading: isLoadingCreate } =
    useCreateWebhook()
  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } =
    useUpdateWebhook()
  const { mutateAsync: mutateAsyncActivate, isLoading: isLoadingActivate } =
    useActivateWebhook()
  const { mutateAsync: mutateAsyncDeactivate, isLoading: isLoadingDeactivate } =
    useDeactivateWebhook()
  const dataCLI = useGetWebhooksCLI(true)
  const { mutateAsync: mutateAsyncUpdateCLI, isLoading: isLoadingUpdateCLI } =
    useUpdateWebhooksCLI()

  const showSkeleton = useMemo(() => !isReady, [isReady])

  const isLoadingSubmit = useMemo(
    () =>
      isLoadingCreate ||
      isLoadingUpdate ||
      isLoadingActivate ||
      isLoadingDeactivate,
    [isLoadingActivate, isLoadingCreate, isLoadingDeactivate, isLoadingUpdate]
  )

  useEffect(() => {
    const applyInitialFilters = async () => {
      if (!isReadyFilters || isInsertedInitialFilters) return

      const query = filters?.query || null
      const typeView = filters?.typeView || 'active'

      await setFilter(FiltersKeyWebhooks.QUERY, query)
      await setFilter(FiltersKeyWebhooks.TYPE_VIEW, typeView)
      setIsInsertedInitialFilters(true)
    }

    applyInitialFilters()
  }, [
    isReadyFilters,
    isInsertedInitialFilters,
    filters?.query,
    setFilter,
    filters?.typeView
  ])

  useEffect(() => {
    if (valueToggle === null && dataCLI?.isSuccess && dataCLI) {
      setValueToggle(dataCLI?.data?.isActive || false)
    }
  }, [dataCLI, valueToggle])

  const generateDynamicColumnWidths = (
    breakpoint: IBreakpoint,
    type: 'active' | 'inactive'
  ) => {
    let baseConfig: any
    if (type === 'active') {
      baseConfig = {
        name: { width: '40%', minWidth: '140px' },
        create: { width: '25%', minWidth: '140px' },
        activate: { width: '25%', minWidth: '140px' },
        actions: { width: '10%', minWidth: '40px' }
      }
    }
    if (type === 'inactive') {
      baseConfig = {
        name: { width: '40%', minWidth: '140px' },
        create: { width: '25%', minWidth: '140px' },
        inactivate: { width: '25%', minWidth: '140px' },
        actions: { width: '10%', minWidth: '40px' }
      }
    }

    const scaleFactor = {
      '3xl': 1,
      '2xl': 1,
      xl: 1,
      lg: 1,
      md: 1.3,
      sm: 1.2,
      xs: 1.2
    }

    const scale = scaleFactor[breakpoint] || 1

    const scaledConfig = Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]: any) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )

    return scaledConfig
  }

  const tableColumnWidth = useMemo(() => {
    return generateDynamicColumnWidths(
      breakpoint,
      filters?.typeView || 'active'
    )
  }, [breakpoint, filters?.typeView])

  const columnsActive: CustomColumn<WebhookActiveType>[] = useMemo(() => {
    return [
      {
        Header: t?.tableInformation?.name || '',
        accessor: 'name',
        Cell: (value: any) => {
          const valuesOriginal = value?.row?.original

          return (
            <button
              className="flex flex-col flex-1 cursor-pointer"
              onClick={() =>
                setModalAction({
                  id: valuesOriginal.id,
                  action: 'view',
                  isOpen: true
                })
              }
            >
              <Typography
                variant="text-sm-medium"
                className="hover:text-gray-500"
              >
                {valuesOriginal.name}
              </Typography>
            </button>
          )
        },
        ...tableColumnWidth.name
      },
      {
        Header: t?.tableInformation?.create || '',
        accessor: 'createdAtUtc',
        Cell: (value: any) => {
          const valuesOriginal = value?.row?.original

          if (!valuesOriginal.createdByName) {
            return (
              <Typography variant="text-xs-regular">
                {formatData(valuesOriginal.createdAtUtc, lang)}
              </Typography>
            )
          }

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                name={valuesOriginal.createdByName}
                imgSrc={valuesOriginal.createdByAvatar}
              />
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.createdByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.createdAtUtc, lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.create
      },
      {
        Header: t?.tableInformation?.activate || '',
        accessor: 'activatedById',
        Cell: (value: any) => {
          const valuesOriginal = value?.row?.original

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                name={valuesOriginal.activatedByName}
                imgSrc={valuesOriginal.activatedByAvatar}
              />
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.activatedByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.activatedAtUtc, lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.activate
      },
      {
        Header: t?.tableInformation?.actions || '',
        accessor: 'id',
        Cell: (value: any) => {
          const valuesOriginal = value?.row?.original

          const menu: MenuItem[] = [
            {
              key: 'view',
              label: t?.options?.view
            },
            {
              key: 'update',
              label: t?.options?.update
            },
            {
              key: 'deactivate',
              label: t?.options?.deactivate
            }
          ]

          return (
            <Dropdown
              menu={menu}
              onSelect={(state) => {
                if (state.key === 'view') {
                  setModalAction({
                    id: valuesOriginal.id,
                    action: 'view',
                    isOpen: true
                  })
                }
                if (state.key === 'update') {
                  setModalAction({
                    id: valuesOriginal.id,
                    action: 'update',
                    isOpen: true
                  })
                }
                if (state.key === 'deactivate') {
                  setModalAction({
                    id: valuesOriginal.id,
                    action: 'deactivate',
                    isOpen: true
                  })
                }
              }}
            >
              <button className="flex items-center justify-center w-8 h-8 transition-all border-none rounded-full cursor-pointer bg-gray-50 hover:bg-gray-100">
                <EllipsisVerticalIcon className="w-5 h-5 text-secondary-700" />
              </button>
            </Dropdown>
          )
        },
        ...tableColumnWidth.actions
      }
    ]
  }, [
    lang,
    t?.options?.deactivate,
    t?.options?.update,
    t?.options?.view,
    t?.tableInformation?.actions,
    t?.tableInformation?.activate,
    t?.tableInformation?.create,
    t?.tableInformation?.name,
    tableColumnWidth.actions,
    tableColumnWidth.activate,
    tableColumnWidth.create,
    tableColumnWidth.name
  ])

  const columnsInactive: CustomColumn<WebhookInactiveType>[] = useMemo(() => {
    return [
      {
        Header: t?.tableInformation?.name || '',
        accessor: 'name',
        Cell: (value: any) => {
          const valuesOriginal = value?.row?.original

          return (
            <button
              className="flex flex-col flex-1 cursor-pointer"
              onClick={() =>
                setModalAction({
                  id: valuesOriginal.id,
                  action: 'view',
                  isOpen: true
                })
              }
            >
              <Typography
                variant="text-sm-medium"
                className="hover:text-gray-500"
              >
                {valuesOriginal.name}
              </Typography>
            </button>
          )
        },
        ...tableColumnWidth.name
      },
      {
        Header: t?.tableInformation?.create || '',
        accessor: 'createdAtUtc',
        Cell: (value: any) => {
          const valuesOriginal = value?.row?.original

          if (!valuesOriginal.createdByName) {
            return (
              <Typography variant="text-xs-regular">
                {formatData(valuesOriginal.createdAtUtc, lang)}
              </Typography>
            )
          }

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                name={valuesOriginal.createdByName}
                imgSrc={valuesOriginal.createdByAvatar}
              />
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.createdByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.createdAtUtc, lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.create
      },
      {
        Header: t?.tableInformation?.deactivate || '',
        accessor: 'deactivatedById',
        Cell: (value: any) => {
          const valuesOriginal = value?.row?.original

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                name={valuesOriginal.deactivatedByName}
                imgSrc={valuesOriginal.deactivatedByAvatar}
              />
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.deactivatedByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.deactivatedAtUtc, lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.inactivate
      },
      {
        Header: t?.tableInformation?.actions || '',
        accessor: 'id',
        Cell: (value: any) => {
          const valuesOriginal = value?.row?.original

          const menu: MenuItem[] = [
            {
              key: 'view',
              label: t?.options?.view
            },
            {
              key: 'update',
              label: t?.options?.update
            },
            {
              key: 'activate',
              label: t?.options?.activate
            }
          ]

          return (
            <Dropdown
              menu={menu}
              onSelect={(state) => {
                if (state.key === 'view') {
                  setModalAction({
                    id: valuesOriginal.id,
                    action: 'view',
                    isOpen: true
                  })
                }
                if (state.key === 'update') {
                  setModalAction({
                    id: valuesOriginal.id,
                    action: 'update',
                    isOpen: true
                  })
                }
                if (state.key === 'activate') {
                  setModalAction({
                    id: valuesOriginal.id,
                    action: 'activate',
                    isOpen: true
                  })
                }
              }}
            >
              <button className="flex items-center justify-center w-8 h-8 transition-all border-none rounded-full cursor-pointer bg-gray-50 hover:bg-gray-100">
                <EllipsisVerticalIcon className="w-5 h-5 text-secondary-700" />
              </button>
            </Dropdown>
          )
        },
        ...tableColumnWidth.actions
      }
    ]
  }, [
    lang,
    t?.options?.activate,
    t?.options?.update,
    t?.options?.view,
    t?.tableInformation?.actions,
    t?.tableInformation?.create,
    t?.tableInformation?.deactivate,
    t?.tableInformation?.name,
    tableColumnWidth.actions,
    tableColumnWidth.create,
    tableColumnWidth.inactivate,
    tableColumnWidth.name
  ])

  const columns: CustomColumn<WebhookActiveType | WebhookInactiveType>[] =
    useMemo(
      () => (filters?.typeView === 'active' ? columnsActive : columnsInactive),
      [columnsActive, columnsInactive, filters?.typeView]
    )

  const handleAction = useCallback(
    async (action: ModalActionType['action'], values: WebhooksFormType) => {
      if (action === 'create') {
        try {
          const response = await mutateAsyncCreate({
            name: values?.name || '',
            description: values?.description || '',
            url: values?.url || '',
            authMethod: values?.authMethod || '',
            httpMethod: values?.httpMethod || '',
            eventTypes: values?.eventTypes || []
          })

          toast.success(t?.toasts?.successCreate)
        } catch (error: any) {
          toast.error(t?.toasts?.errorCreate)
          const status = error?.response?.status
          if (status === 402) {
            setModalError(true)
          }
        } finally {
          setModalAction({
            id: null,
            action: null,
            isOpen: false
          })
        }
      }
      if (action === 'update') {
        try {
          await mutateAsyncUpdate({
            id: modalAction.id as string,
            name: values?.name || '',
            description: values?.description || '',
            url: values?.url || '',
            authMethod: values?.authMethod || '',
            httpMethod: values?.httpMethod || '',
            eventTypes: values?.eventTypes || []
          })
          toast.success(t?.toasts?.successUpdate)
        } catch (error: any) {
          toast.error(t?.toasts?.errorUpdate)
          const status = error?.response?.status
          if (status === 402) {
            setModalError(true)
          }
        } finally {
          setModalAction({
            id: null,
            action: null,
            isOpen: false
          })
        }
      }
    },
    [
      modalAction.id,
      mutateAsyncCreate,
      mutateAsyncUpdate,
      t?.toasts?.errorCreate,
      t?.toasts?.errorUpdate,
      t?.toasts?.successCreate,
      t?.toasts?.successUpdate
    ]
  )

  const handleConfirm = useCallback(
    async (action: 'activate' | 'deactivate', id: string) => {
      if (action === 'activate') {
        try {
          await mutateAsyncActivate(id)
          toast.success(t?.toasts?.successActivate)
        } catch (error) {
          toast.error(t?.toasts?.errorActivate)
        } finally {
          setModalAction({
            id: null,
            action: null,
            isOpen: false
          })
        }
      }
      if (action === 'deactivate') {
        try {
          await mutateAsyncDeactivate(id)
          toast.success(t?.toasts?.successDeactivate)
        } catch (error) {
          toast.error(t?.toasts?.errorDeactivate)
        } finally {
          setModalAction({
            id: null,
            action: null,
            isOpen: false
          })
        }
      }
    },
    [
      mutateAsyncActivate,
      mutateAsyncDeactivate,
      t?.toasts?.errorActivate,
      t?.toasts?.errorDeactivate,
      t?.toasts?.successActivate,
      t?.toasts?.successDeactivate
    ]
  )

  const dataList = useMemo(() => {
    if (filters?.typeView === 'active') {
      return {
        data: dataListActive,
        isLoading: isLoadingActive,
        isSuccess: isSuccessActive
      }
    }
    return {
      data: dataListInactive,
      isLoading: isLoadingInactive,
      isSuccess: isSuccessInactive
    }
  }, [
    dataListActive,
    dataListInactive,
    filters?.typeView,
    isLoadingActive,
    isLoadingInactive,
    isSuccessActive,
    isSuccessInactive
  ])

  const handleToggleCLI = useCallback(
    async (isActive: boolean) => {
      const urlExists =
        dataCLI?.data?.url?.length && dataCLI?.data?.url?.length > 0

      // Verificar se a url existe
      if (urlExists) {
        // Agora verifica se está ativo, se estiver ativo, desativa, se estiver desativado, ativa
        setValueToggle(isActive)
        try {
          await mutateAsyncUpdateCLI({
            url: dataCLI?.data?.url || '',
            isActive
          })
        } catch (error: any) {
          const status = error?.response?.status
          if (status === 402) {
            setModalError(true)
          }
          setValueToggle(!isActive)
        }
      }
      // Se não existir a url, abrimos uma modal para o usuário inserir a url
      setModalAction({
        isOpen: true,
        action: 'editCLI',
        id: null
      })
    },
    [dataCLI, mutateAsyncUpdateCLI]
  )

  return {
    columns,
    dataList,
    handleAction,
    handleConfirm,
    showSkeleton,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    modalAction,
    setModalAction,
    isLoadingSubmit,
    modalError,
    setModalError,
    filters,
    handleApplyFilters,
    preparedFilters,
    setPreparedFilters,
    valueToggle,
    handleToggleCLI,
    isLoadingUpdateCLI,
    dataCLI
  }
}

export default useWebhooksData
