import { useCallback, useMemo, useState } from 'react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { push } from 'mixpanel-browser'
import { toast } from 'react-toastify'
import { formatData } from '@/utils/date'
import { useLocale } from '@/providers/Locale'
import { CustomColumn } from '@/types/react-table-config'
import { Avatar, Dropdown, MenuItem, Typography } from '@/atoms/index'
import {
  ModalActionType,
  UseApiTokensDataResponse
} from './useApiTokensData.types'
import useApiTokens from '../api/administration/user-account-accesses/useApiTokens'
import {
  ApiTokenBase,
  CreateApiTokenRequest
} from '../api/administration/user-account-accesses/useApiTokens/useApiTokens.types'
import { useTable } from '../useTable'
import useTranslation from '../useTranslation'
import { useViewport } from '../useViewport'
import { IBreakpoint } from '../useViewport/useViewport'

const useApiTokensData = (): UseApiTokensDataResponse => {
  const { t, isReady } = useTranslation('profileApiTokens')
  const [modalAction, setModalAction] = useState<ModalActionType>({
    isOpen: false,
    action: null,
    id: null
  })
  const [modalErrorAddonApi, setModalErrorAddonApi] = useState(false)

  const { pageNumber, pageSize, setPageNumber, setPageSize } = useTable()
  const { breakpoint } = useViewport()
  const { lang } = useLocale()
  const {
    useCreateApiToken,
    useUpdateApiToken,
    useDeleteApiToken,
    useGetApiTokens
  } = useApiTokens()
  const { mutateAsync: mutateAsyncCreate, isLoading: isLoadingCreate } =
    useCreateApiToken()
  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } =
    useUpdateApiToken()
  const { mutateAsync: mutateAsyncDelete, isLoading: isLoadingDelete } =
    useDeleteApiToken()
  const dataList = useGetApiTokens(pageNumber, pageSize)

  const showSkeleton = useMemo(() => !isReady, [isReady])

  const isLoadingSubmit = useMemo(
    () => isLoadingCreate || isLoadingDelete || isLoadingUpdate,
    [isLoadingCreate, isLoadingDelete, isLoadingUpdate]
  )

  const generateDynamicColumnWidths = (breakpoint: IBreakpoint) => {
    const baseConfig = {
      name: { width: '40%', minWidth: '140px' },
      create: { width: '25%', minWidth: '140px' },
      update: { width: '25%', minWidth: '140px' },
      actions: { width: '10%', minWidth: '40px' }
    }

    const scaleFactor = {
      '3xl': 1,
      '2xl': 1,
      xl: 1,
      lg: 1,
      md: 1.3,
      sm: 1.2,
      xs: 1.2
    }

    const scale = scaleFactor[breakpoint] || 1

    const scaledConfig = Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )

    return scaledConfig
  }

  const tableColumnWidth = useMemo(() => {
    return generateDynamicColumnWidths(breakpoint)
  }, [breakpoint])

  const columns: CustomColumn<ApiTokenBase>[] = useMemo(() => {
    return [
      {
        Header: t?.tableInformation?.name || '',
        accessor: 'name',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <button
              className="flex flex-col flex-1 cursor-pointer"
              onClick={() =>
                setModalAction({
                  id: valuesOriginal.id,
                  action: 'view',
                  isOpen: true
                })
              }
            >
              <Typography
                variant="text-sm-medium"
                className="hover:text-gray-500"
              >
                {valuesOriginal.name}
              </Typography>
            </button>
          )
        },
        ...tableColumnWidth.name
      },
      {
        Header: t?.tableInformation?.create || '',
        accessor: 'createdAtUtc',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          if (!valuesOriginal.createdByName) {
            return (
              <Typography variant="text-xs-regular">
                {formatData(valuesOriginal.createdAtUtc, lang)}
              </Typography>
            )
          }

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                name={valuesOriginal.createdByName}
                imgSrc={valuesOriginal.createdByAvatar}
              />
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.createdByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.createdAtUtc, lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.create
      },
      {
        Header: t?.tableInformation?.update || '',
        accessor: 'updatedByName',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          if (!valuesOriginal.updatedByName) {
            return (
              <Typography variant="text-xs-regular">
                {formatData(valuesOriginal.updatedAtUtc ?? '', lang)}
              </Typography>
            )
          }

          return (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                imgSrc={valuesOriginal.updatedByAvatar}
                name={valuesOriginal.updatedByName ?? ''}
              />
              <div className="flex flex-col flex-1">
                <Typography variant="text-sm-medium">
                  {valuesOriginal.updatedByName}
                </Typography>
                <Typography variant="text-xs-regular">
                  {formatData(valuesOriginal.updatedAtUtc ?? '', lang)}
                </Typography>
              </div>
            </div>
          )
        },
        ...tableColumnWidth.update
      },
      {
        Header: t?.tableInformation?.actions || '',
        accessor: 'id',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          const menu: MenuItem[] = [
            {
              key: 'view',
              label: t?.options?.view
            },
            {
              key: 'update',
              label: t?.options?.update
            },
            {
              key: 'delete',
              label: t?.options?.delete
            }
          ]

          return (
            <Dropdown
              menu={menu}
              onSelect={(state) => {
                if (state.key === 'view') {
                  setModalAction({
                    id: valuesOriginal.id,
                    action: 'view',
                    isOpen: true
                  })
                }
                if (state.key === 'update') {
                  setModalAction({
                    id: valuesOriginal.id,
                    action: 'update',
                    isOpen: true
                  })
                }
                if (state.key === 'delete') {
                  setModalAction({
                    id: valuesOriginal.id,
                    action: 'delete',
                    isOpen: true
                  })
                }
              }}
            >
              <button className="flex items-center justify-center w-8 h-8 transition-all border-none rounded-full cursor-pointer bg-gray-50 hover:bg-gray-100">
                <EllipsisVerticalIcon className="w-5 h-5 text-secondary-700" />
              </button>
            </Dropdown>
          )
        },
        ...tableColumnWidth.actions
      }
    ]
  }, [
    lang,
    t?.options?.delete,
    t?.options?.update,
    t?.options?.view,
    t?.tableInformation?.actions,
    t?.tableInformation?.create,
    t?.tableInformation?.name,
    t?.tableInformation?.update,
    tableColumnWidth.actions,
    tableColumnWidth.create,
    tableColumnWidth.name,
    tableColumnWidth.update
  ])

  const handleAction = useCallback(
    async (
      action: ModalActionType['action'],
      values: CreateApiTokenRequest
    ) => {
      if (action === 'create') {
        try {
          const response = await mutateAsyncCreate({
            name: values?.name || '',
            description: values?.description || ''
          })
          toast.success(t?.toasts?.successCreate)
          if (response?.id) {
            setModalAction({
              id: response.id,
              action: 'view',
              isOpen: true
            })
            return
          }
          setModalAction({
            id: null,
            action: null,
            isOpen: false
          })
        } catch (error: any) {
          setModalAction({
            id: null,
            action: null,
            isOpen: false
          })
          toast.error(t?.toasts?.errorCreate)
          const status = error?.response?.status
          if (status === 402) {
            setModalErrorAddonApi(true)
          }
        }
      }
      if (action === 'update') {
        try {
          await mutateAsyncUpdate({
            id: modalAction.id as string,
            name: values?.name || '',
            description: values?.description || ''
          })
          toast.success(t?.toasts?.successUpdate)
        } catch (error: any) {
          toast.error(t?.toasts?.errorUpdate)
          const status = error?.response?.status
          if (status === 402) {
            setModalErrorAddonApi(true)
          }
        } finally {
          setModalAction({
            id: null,
            action: null,
            isOpen: false
          })
        }
      }
    },
    [
      modalAction.id,
      mutateAsyncCreate,
      mutateAsyncUpdate,
      t?.toasts?.errorCreate,
      t?.toasts?.errorUpdate,
      t?.toasts?.successCreate,
      t?.toasts?.successUpdate
    ]
  )

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        await mutateAsyncDelete(id)
        toast.success(t?.toasts?.successDelete)
      } catch (error) {
        toast.error(t?.toasts?.errorDelete)
        console.log('error', error)
      } finally {
        setModalAction({
          id: null,
          action: null,
          isOpen: false
        })
      }
    },
    [mutateAsyncDelete, t?.toasts?.errorDelete, t?.toasts?.successDelete]
  )

  return {
    columns,
    dataList,
    handleAction,
    handleDelete,
    showSkeleton,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    modalAction,
    setModalAction,
    isLoadingSubmit,
    modalErrorAddonApi,
    setModalErrorAddonApi
  }
}

export default useApiTokensData
