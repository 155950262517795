import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import { ForgotPasswordInsertEmail, RecoveryPassword } from '@/organisms/index'
import AuthTemplate from '@/templates/AuthTemplate/AuthTemplate'

const ForgotPasswordPage = () => {
  const { query } = useRouter()

  const isSectionInsertEmail = !query?.email

  return (
    <AuthTemplate>
      {isSectionInsertEmail ? (
        <ForgotPasswordInsertEmail />
      ) : (
        <RecoveryPassword email={query?.email as string} />
      )}
      <NextSeo
        noindex
        nofollow
        title="Signater - Esqueci minha senha"
        description="Recupere sua senha Signater"
        canonical="https://app.signater.com.br/forgot-password"
        openGraph={{
          url: 'https://app.signater.com.br/forgot-password',
          title: 'Signater - Esqueci minha senha',
          description: 'Recupere sua senha Signater',
          type: 'website',
          images: [{ url: '/public/meta/signater.webp' }],
          siteName: 'Signater',
          locale: 'pt_BR'
        }}
        twitter={{
          site: 'https://signater.com.br',
          cardType: 'summary_large_image'
        }}
      />
    </AuthTemplate>
  )
}

export default ForgotPasswordPage
