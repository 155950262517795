import { useRouter } from 'next/router'
import useTranslation from '@/hooks/useTranslation'
import { ApiTokenBase } from '@/hooks/api/administration/user-account-accesses/useApiTokens/useApiTokens.types'
import useApiTokensData from '@/hooks/useApiTokensData/useApiTokensData'
import { Typography } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'
import {
  EmptyStateTable,
  ModalActionApiTokens,
  ModalConfirm,
  ModalHelpInfo
} from '@/ui/molecules'
import { Table } from '@/ui/organisms'
import { SettingsTemplate } from '@/ui/templates'

const ProfileApiTokensPage: React.FC = () => {
  const { t, isReady } = useTranslation('profileApiTokens')
  const { push } = useRouter()

  const {
    columns,
    dataList,
    handleAction,
    handleDelete,
    showSkeleton,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    modalAction,
    setModalAction,
    isLoadingSubmit,
    modalErrorAddonApi,
    setModalErrorAddonApi
  } = useApiTokensData()

  return (
    <>
      <SettingsTemplate
        showSkeleton={showSkeleton}
        className="w-full sm:w-full xl:min-w-4xl"
        renderHeader={
          <div className="flex items-center justify-between w-full gap-2">
            <div className="flex flex-col w-full gap-1">
              <Typography
                variant="title-2xl-medium"
                className="text-gray-700 dark:text-gray-700"
                type="h1"
                classNameSkeleton="w-40"
                showSkeleton={showSkeleton}
              >
                {t?.title}
              </Typography>
              <Typography
                variant="text-sm-regular"
                className="text-gray-700 dark:text-gray-700"
                type="p"
                showSkeleton={showSkeleton}
              >
                {t?.description}
              </Typography>
            </div>
            <div className="flex items-center justify-end w-full h-full gap-2">
              <Button
                onClick={() =>
                  setModalAction({
                    action: 'create',
                    isOpen: true,
                    id: null
                  })
                }
                data-testid="button-create"
                showSkeleton={showSkeleton}
              >
                {t?.buttonCreate}
              </Button>
            </div>
          </div>
        }
      >
        {dataList?.isLoading ||
        (dataList?.isSuccess &&
          dataList?.data &&
          dataList?.data?.items?.length > 0) ? (
          <div className="flex items-center justify-center">
            <Table<ApiTokenBase>
              columns={columns}
              data={dataList?.data?.items || []}
              fetchData={setPageNumber}
              canPreviousPage={pageNumber > 0}
              canNextPage={
                (dataList?.data?.pagination &&
                  dataList?.data?.pagination?.totalItems /
                    dataList?.data?.pagination?.pageSize >
                    pageNumber + 1) ||
                false
              }
              pagination={dataList?.data?.pagination}
              isLoading={dataList?.isLoading}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
            />
          </div>
        ) : (
          <EmptyStateTable type="profileApiTokens" />
        )}
      </SettingsTemplate>
      {(modalAction.action === 'create' ||
        modalAction.action === 'update' ||
        modalAction.action === 'view') && (
        <ModalActionApiTokens
          isOpen={modalAction.isOpen}
          action={modalAction.action}
          onClose={() =>
            setModalAction({ isOpen: false, action: null, id: null })
          }
          onSubmit={(action, values) => handleAction(action, values)}
          setModalAction={setModalAction}
          isLoadingSubmit={isLoadingSubmit}
          id={modalAction.id}
        />
      )}
      <ModalConfirm
        isOpen={modalAction.action === 'delete' && modalAction.isOpen}
        title={t?.modalDelete?.title}
        description={t?.modalDelete?.description}
        cancelButtonText={t?.modalDelete?.cancelButtonText}
        confirmButtonText={t?.modalDelete?.confirmButtonText}
        onClose={() =>
          setModalAction({ isOpen: false, action: null, id: null })
        }
        onConfirm={async () => await handleDelete(modalAction.id as string)}
        loadingSubmit={isLoadingSubmit}
      />
      <ModalHelpInfo
        isOpen={modalErrorAddonApi}
        onClose={() => setModalErrorAddonApi(false)}
        title={t?.modalErrorAddonApi?.title}
        description={t?.modalErrorAddonApi?.description}
        status="error"
        continueButtonText={t?.modalErrorAddonApi?.continueButtonText}
        onContinue={() => push('/billing/plans')}
      />
    </>
  )
}

export default ProfileApiTokensPage
